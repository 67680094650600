import * as d3 from 'd3';
import dayjs from 'dayjs';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useInitData } from '@vkruglikov/react-telegram-web-app';

import ProgressBar from './components/Progressbar';

import header_logo from './media/aviatorlogo.svg';
import rotate_png from './media/rotate.png';
import plan_png from './media/plan.png';
import plan_bg_png from './media/bgplan.png';
import app_bg from './media/newbg.jpg';

import './App.css';

const App = () => {
    const [rotatePreload, setRotatePreload] = useState(false);
    const [animationStarted, setAnimationStarted] = useState(false);
    const [coefficient, setcoefficient] = useState('0.00');
    const [progress, setProgress] = useState(0);
    const [signaldelay, setSignaldelay] = useState(0);
    const [airplane, setAirplane] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [chance, setChange] = useState('0');
    const [availableSignals, setAvailableSignals] = useState(0);
    const [cooldown, setCooldown] = useState(0);
    const [user, setUser] = useState(null); 

    const [initDataUnsafe] = useInitData();
    const [loading, setLoading] = useState(true);

    const getSignal = (coef_target) => {
        if (animationStarted) return;

        setAnimationStarted(true);

        [...document.querySelector('#chart-up').children].forEach((node) => {
            node.remove()
        });

        const img = d3.select("#chart-airplane");
        const svgWidth = document.querySelector('.chart-container').clientWidth;
        const svgHeight = document.querySelector('.chart-container').clientHeight;

        const svg = d3.select("#chart-up")
            .attr("width", svgWidth)
            .attr("height", svgHeight);

        // Функция для создания данных с прогрессивным шагом
        function generateProgressiveData(start, end, steps) {
            let data = [];
            let value = start;
            let stepIncrement = 0.05; // начальный шаг
            let step = 0.1;  // стартовое увеличение
            for (let i = 0; i < steps; i++) {
                data.push(value);
                value += step;   // Увеличиваем значение
                step += stepIncrement;  // Увеличиваем шаг на небольшую величину
            }
            return data;
        }

        // Генерация данных
        const data = generateProgressiveData(0.1, 1.68, 30);

        const xScale = d3.scaleLinear()
            .domain([0, data.length - 1])
            .range([0, svgWidth]);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data)])
            .range([svgHeight, 0]);

        // Линейная область с заливкой
        const area = d3.area()
            .x((d, i) => xScale(i))
            .y0(svgHeight)  // Нижняя часть области (по оси Y)
            .y1(d => yScale(d))  // Верхняя часть (где проходит линия)
            .curve(d3.curveMonotoneX);  // Линейная кривая с плавным изменением

        // Линейная кривая (красная линия поверх заливки)
        const line = d3.line()
            .x((d, i) => xScale(i))
            .y(d => yScale(d))
            .curve(d3.curveMonotoneX);  // Линейная кривая

        // Изначально рисуем путь с одной точкой
        const areaPath = svg.append("path")
            .datum(data.slice(0, 1))  // Начинаем с одной точки
            .attr("fill", "rgba(255, 0, 0, 0.3)")
            .attr("d", area);

        const linePath = svg.append("path")
            .datum(data.slice(0, 1))  // Начинаем с одной точки
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 3)
            .attr("d", line);


        function animatePath() {
            setAirplane(true);
            setRotatePreload(false);
            let i = 1;

            const target = coef_target;
            const steps = data.length;
            const duration = 20; 
            const increment = (target / steps).toFixed(2);

            let currentValue = 0.00;
            let stepCount = 0;

            function incrementValue() {
                if (stepCount < steps) {
                    currentValue = (parseFloat(currentValue) + parseFloat(increment)).toFixed(2);  // Инкрементируем значение
                    setcoefficient(currentValue);
                    stepCount++;

                    setTimeout(incrementValue, duration); 
                } else {
                    setcoefficient(target.toFixed(2));
                }
            }

            incrementValue()
            
            function step() {
                areaPath.datum(data.slice(0, i + 1))
                    .transition()
                    .duration(10)
                    .attr("d", area);

                linePath.datum(data.slice(0, i + 1))
                    .transition()
                    .duration(20)
                    .attr("d", line);

                const x = xScale(i);
                const y = yScale(data[i]);

                img
                    .style("left", `${x}px`)
                    .style("top", `${y - 75}px`);

                i++;
                if (i < data.length) {
                    setTimeout(step, 20);
                }

                if (i === data.length - 1){
                    setAirplane(false);
                }
            }

            step();
        }

        setRotatePreload(true);
        setTimeout(() => {
            animatePath();
            setAnimationStarted(false);
        }, 600);
    };

    //dev
    // useEffect(() => {
    //     setCooldown(1000);
    // }, []);

    useEffect(() => {
        setLoading(true);

        const get_user = async () => {
            return axios.get('https://aviator.tg.normalizex.dev/api/user?q=' + initDataUnsafe.user.id).then((response) => {
                setAvailableSignals(response.data.available);
                setSignaldelay(response.data.delay);
                setCooldown(response.data.cooldown);
                if (response.data.available > 0){
                    setDisabled(false);
                }
                setUser(response.data);
            }).catch(() => {
                setAvailableSignals(0);
            });
        }

        get_user().catch(_ => {
            console.log('errr with fetch');
        }).finally(() => {
            setLoading(false);
        });

        const userupdateInterval = setInterval(() => {
            get_user().catch(_ => {
                console.log('errr with fetch');
            })
        }, 60 * 1000);

        return () => {
            clearInterval(userupdateInterval);
        }
    }, [initDataUnsafe]);

    useEffect(() => {
        if (signaldelay > 0){
            const signal_timeout = setTimeout(() => {
                setSignaldelay((current) => {
                    if (current <= 1){
                        clearTimeout(signal_timeout);
                        return 0;
                    }else{
                        return current - 1;
                    }
                });
            }, 1000);

            return () => {
                clearTimeout(signal_timeout);
            }
        }
    }, [signaldelay]);

    const updateProgress = async () => {
        let process = localStorage.getItem('process-active');
        if (process === 'false') return;

        setProgress((prevProgress) => {
            if (prevProgress >= 130) {
                localStorage.setItem('process-active', 'false');
                setProgress(0);
                setTimeout(() => {
                    axios.post('https://aviator.tg.normalizex.dev/api/user/signal?q=' + initDataUnsafe.user.id).then((response) => {
                        if (response.data.available > 0){
                            getSignal(response.data.coefficient);
                            setChange(response.data.chance)
                        }
                        setAvailableSignals(response.data.available);
                        setSignaldelay(response.data.delay);
                        setCooldown(response.data.cooldown);
                    }).catch(err => {
                        if (err.response){
                            if (err.response.data.coefficient !== 0 && err.response.data.chance !== 0){
                                getSignal(err.response.data.coefficient);
                                setChange(err.response.data.chance)
                            }

                            setAvailableSignals(err.response.data.available);
                            setSignaldelay(err.response.data.delay);
                            setCooldown(err.response.data.cooldown);
                        };
                    })
                }, 250);

                return prevProgress;
            }
            else if (prevProgress >= 0 && prevProgress <= 10){
                return prevProgress + 32;
            }
            else if (prevProgress > 10 && prevProgress <= 40){
                return prevProgress + 20;
            }
            else if (prevProgress > 40 && prevProgress <= 70) {
                return prevProgress + 16.1;
            }
            else if (prevProgress > 70) {
                return prevProgress + 12;
            }
        })
    }

    useEffect(() => {
        localStorage.setItem('process-active', 'false');
        const progressBarInterval = setInterval(() => {
            updateProgress();
        }, 100);

        return () => {
            clearInterval(progressBarInterval);
        }
    }, []);

    if (loading){
        return (
            <div className='App'>

            </div>
        )
    }

    if (cooldown > 0){
        return (
            <div>
                <div className='center-app'>
                    <div className='center-box '>
                        <div className='item'>
                            <div className='item'>
                                <h3>Oops, signals have run out ⚠️</h3>
                                <h3>Please wait a bit ⏳</h3>
                            </div>
                            <div className='item' style={{ marginTop: '20%' }}>
                            <h3>Remaining: {Math.floor(cooldown / 3600).toString().length === 1 ? '0' + Math.floor(cooldown / 3600) : Math.floor(cooldown / 3600)}:{Math.floor((cooldown % 3600) / 60).toString().length === 1 ? '0' + Math.floor((cooldown % 3600) / 60) : Math.floor((cooldown % 3600) / 60)}</h3>
                                <ProgressBar current={10800 - cooldown} max={10800} />

                                <h3>🚀 While you're waiting, why not check other bot? <a className='btn' href={`https://t.me/luckies_mines_bot${user.code ? "?start=" + user.code : ''}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', padding: '0' }}> Mines 💣 </a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='App'>
            <div class="item header">
                <img alt='left' className='left-image' src={header_logo} />
            </div>
            <div className="item">
                <div className="chart-container" style={{ backgroundImage: `url(${plan_bg_png})`, overflow: 'hidden' }}>
                    <div className='chart-wrapper' style={{ height: '99%' }}>
                        <div id="chart" style={{ display: rotatePreload ? 'none' : 'block' }}>
                            <div id='chart-coefficient' class="neon-text growth-text"><span>{coefficient}</span><span style={{ fontSize: '0.75em' }}>x</span></div>
                            <img id='chart-airplane' style={{ display: airplane ? 'block' : 'none' }} src={plan_png} alt="airplane" />
                            <svg id='chart-up'/>
                        </div>
                    </div>
                    <div id="rotate" style={{ display: rotatePreload ? 'block' : 'none' }}>
                        <img id="rotating-image" src={rotate_png} alt="Rotating Image" />
                    </div>
                </div>
            </div>
            <div className="item">
                <ProgressBar current={progress} max={100} />
            </div>
            <div className="item text-left">
                <div className='text-container'>
                    <p className='neon-white-text'>Chance: <span className='red'>{chance}%</span></p>
                    { cooldown ? <p className='neon-white-text'>Cooldown: {Math.floor(cooldown / 3600)} hours {Math.floor((cooldown % 3600) / 60)} minutes</p> : <p className='neon-white-text'>Before The Signal: {signaldelay}</p> }
                </div>
            </div>
            <div className="item">
                <button 
                    disabled={disabled} 
                    className={`btn ${disabled || availableSignals == 0 || signaldelay > 0 || cooldown > 0 ? 'btn-disabled' : ''}`} 
                    style={{ marginBottom: '30px' }} 
                    onClick={() => {
                        if (disabled || signaldelay > 0 || cooldown ) return;
                        if (localStorage.getItem('process-active') === 'true') return;

                        if (signaldelay === 0) {
                            axios.get('https://aviator.tg.normalizex.dev/api/user?q=' + initDataUnsafe.user.id).then((response) => {
                                setAvailableSignals(response.data.available);
                                setSignaldelay(response.data.delay);
                                if (response.data.available > 0){
                                    setDisabled(false);
                                    localStorage.setItem('process-active', 'true');
                                }
                            }).catch(() => {
                                setAvailableSignals(0);
                            });
                        };
                }}>
                    GET SIGNAL
                </button>
            </div>
        </div>
    )
}

export default App;